/** @jsx jsx */
import { jsx } from 'theme-ui';
import SectionHeader from '../Common/SectionHeader';
import { Link } from 'gatsby';

const WhyDonate = () => {
  return (
    <section>
      <div
        className="text-content"
        sx={{
          variant: 'text.normal',
        }}
      >
        <SectionHeader heading="Why Donate Your Embryos to the NEDC?" />

        <p sx={{ mb: '1.5rem' }}>
          Many donate embryos through the NEDC mainly because we are a well-established organization
          with long-term success in providing safe storage for all embryos. We also have many years
          of experience in facilitating successful embryo adoptions with recipient couples who have
          met all criteria to be eligible to adopt embryos that were donated both openly and
          anonymously.
        </p>
        <p>There are many other reasons to donate your embryos through the NEDC.</p>
        <ul
          sx={{
            li: { mb: '1rem' },
          }}
        >
          <li>
            <b>Experience matters!</b> We are the largest embryo donation program in the country and
            have received and cared for over 2500 sets of frozen embryos. Our experienced 
            embryologists arrange for care after arrival. They can confidently handle the thawing of 
            embryos frozen by a wide variety of protocols as embryos from all over the country 
            have been donated to the NEDC.
          </li>
          <li>
            <b>Values matter!</b> It’s all right there in our Mission and Value Statement:
            <Link to="/about/">(https://www.embryodonation.org/about/)</Link>. All of us here at the
            NEDC value the human lives which are your embryos. We treat them with the care and
            respect they deserve. We are dedicated to finding the best possible homes for the
            children through the donation of your embryos. Therefore, we have age, weight and health
            restrictions on our adopting couples, and we also require a home study of adopting
            couples so you can be certain your embryos are going to the best possible family.
          </li>
          <li>
            <b>Screening matters!</b> We medically screen couples to ensure 
            that your embryos will have a high likelihood of implanting and ultimately resulting in a live birth. 
            This necessitates excluding some couples who have social or medical conditions 
            such as nicotine, drug, or alcohol addiction, a history of recurrent implantation
            failure with previous embryo transfers, or serious gynecologic conditions such as
            endometriosis, fibroids, or adenomyosis.
          </li>
          <li>
            <b>Flexibility matters!</b> We realize that not all couples are comfortable with all
            forms of embryo donation. Therefore, we offer the option of donating your embryos both
            anonymously and openly. Furthermore, open donation can be tailored to your individual
            needs and desires, with all degrees of information sharing possible, making you feel
            more comfortable with your decision. We have a dedicated Social Work group (Christian Adoption Consultants) who reviews all home studies, and mediates Open Donation Agreements
            (ODAs). This allows for a seamless experience for you without having to resort to
            expensive attorneys or other intermediaries.
          </li>
          <li>
            <b>Cost matters!</b> There are NO FEES to you for donating your embryos. 
            And once we have received them from your clinic, 
            you will no longer be liable for storage costs from them.
          </li>
          <li>
            <b>Success matters!</b> We have outstanding staff, 
            which has resulted in being blessed with the highest embryo adoption pregnancy rates 
            in the nation as well as the most births from embryo donation in the entire world.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WhyDonate;
